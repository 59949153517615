<!-- =========================================================================================
  File Name: ServiceView.vue
  Description: Service View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/service/pixinvent
========================================================================================== -->

<template>
  <div id="page-service-view">

    <vs-alert :active.sync="service_not_found" color="danger" title="Service Not Found">
      <span>Service record with id: {{ $route.params.serviceId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link :to="{name:'page-service-list'}" class="text-inherit underline">All Services</router-link>
      </span>
    </vs-alert>

    <div id="service-data" v-if="service_data">

      <vx-card :title="service_data.name" class="mb-base" style="min-height:60px;">

        <vs-button v-if="oauth_secret" :to="{name: 'app-service-edit', params: { serviceId: $route.params.serviceId }}" class="mr-4" icon="icon-edit" icon-pack="feather">
          Edit
        </vs-button>

      </vx-card>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="Information">
            <table>
              <tr>
                <td class="font-semibold">Service Name</td>
                <td>{{ service_data.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Service OAuth ID</td>
                <td>{{ service_data.oauth_id }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Internal App?</td>
                <td>{{ service_data.internal ? 'Yes' : 'No' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Service Owner</td>
                <td>
                  <router-link ref="owner_name" :to="`/a/users/${service_data.owner_id}`">{{ owner_name }}</router-link>
                </td>
              </tr>
              <tr v-if="service_data.secret">
                <td class="font-semibold">Oauth Secret</td>
                <td @click="revealOauthSecret">{{ oauth_secret }}</td>
              </tr>
            </table>
          </vx-card>
        </div>

        <div class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="URIs">
            <table>
              <tr>
                <td class="font-semibold">Landing Uri</td>
                <td><a target="_blank" :href="service_data.uris.landing">{{ service_data.uris.landing }}</a></td>
              </tr>
              <tr v-if="service_data.uris.oauth">
                <td class="font-semibold">Oauth Uri</td>
                <td><a target="_blank" :href="service_data.uris.oauth">{{ service_data.uris.oauth }}</a></td>
              </tr>
              <tr v-if="service_data.uris.webhook">
                <td class="font-semibold">Webhook Uri</td>
                <td><a target="_blank" :href="service_data.uris.webhook">{{ service_data.uris.webhook }}</a></td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      service_data: null,
      service_not_found: false,
      attributes: {},
      owner_name: 'loading...',
      oauth_secret: 'Click to Reveal'
    };
  },
  methods: {
    revealOauthSecret() {
      if (this.oauth_secret === 'Click to Reveal') {
        this.oauth_secret = this.service_data.secret;
      } else {
        this.oauth_secret = 'Click to Reveal'
      }
    },
    redirectToChild(id) {
      this.$router.push(`/a/services/${id}`);
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Archive',
        text: `Are you sure you want to archive this service?`,
        accept: this.deleteRecord,
        acceptText: 'Archive',
      });
    },
    deleteRecord() {

      this.$vs.loading();

      this.$http.delete(`services/${this.service_data.id}`)
        .then(response => {
          this.$vs.loading.close();

          if (response.status === 200) {
            this.service_data = response.data.data;

            return this.$vs.notify({
              color: 'success',
              title: 'Service Archived',
              text: 'You have successfully archived this service',
              position: 'top-right',
            });
          }

          this.showError({response});
        })
        .catch(this.showError);

    },
    showError(exception) {
      this.$vs.loading.close();

      let error = 'An unknown error occurred while modifying this service';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to update service',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
  created() {

    const serviceId = this.$route.params.serviceId;

    this.$http.get(`services/${serviceId}`)
      .then(response => {

        if (response.data.data) {
          this.service_data = response.data.data;
          this.owner_name = this.service_data.owner_id;

          this.$http.get(`users/${this.service_data.owner_id}`)
            .then(response => {
              if (response.status === 200) {
                const data = response.data.data;
                this.owner_name = `${data.first_name} ${data.last_name}`
              }
            })

        }

      })
      .catch(error => {

        if (error.response && error.response.status === 404) {
          this.service_not_found = true;
        }

      });
  },
};

</script>

<style lang="scss">
#avatar-col {
  width: 15rem;
}

#page-service-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
